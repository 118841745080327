
.carousel-container{
  max-width: 400px;
  max-height: 500px;    
  margin: auto;
  padding: 10px;
}

.project-edit-image{
  width: auto;
  height: auto;
  max-width: 400px;
  max-height: 400px; 
}

.project-edit-image img{
  width: auto !important;
  height: auto;
  max-width: 400px;
  max-height: 400px; 
}

.project-edit-thumbnail{  
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 100px; 
}

.project-edit-thumbnail img{  
  width: auto !important;
  height: auto;
  max-width: 100px;
  max-height: 100px; 
}

