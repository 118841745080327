.home-slider-image{
    width: auto;
    height: auto;    
  }
  
  .home-slider-image img{
    width: 100% !important; 
    height: 80vh;   
  }  
  
  .home-products-btn{
    position: fixed;
    z-index: 1000;
    top: 65%;
    left: 43%;
  }

  .home-products-btn button{
    width: 200px;
    height: 55px;
    border: solid 2px #fff;
  }

  @media(max-width: 800px){
    .home-products-btn{
        left: 25%;
        top: 40%;
    }
  }
