.div-main-container{
    background-color: #e9e6e6 !important;
    height: 100%;
}

.grid-picture-description{
    background-color: #fff !important; 
    min-height: 95vh !important;
    width: 66% !important;
    margin-top: 20px !important;
}

.product-edit-main-image{
    margin-top: 10px;
    width: 90%;
}

.product-edit-title{
    padding-top: 10px;
}

.product-edit-right-border{
    border-right: solid 1px #ccc;
}

.product-edit-bottom-border{
    border-bottom: solid 1px #ccc;
}

.product-edit-separator{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 10px;
}

.product-edit-description{
    text-align: left;
    padding: 10px;
}

.product-edit-options{
    background-color: #f8f8f8;
    margin-top: 20px;
    padding: 10px;
}

.product-edit-options-colors{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-colors-div{
    -webkit-flex-grow: 0; /* Safari 6.1+ */
    -webkit-flex-shrink: 0; /* Safari 6.1+ */
    -webkit-flex-basis: 32px; /* Safari 6.1+ */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32px;
    margin-right: 10px;
    height: 32px;
    padding-left: 2px;
    padding-top: 2px;
}

.product-edit-options-colors-div:hover{
    border: solid 1px #ccc;
}

.product-edit-options-colors-selected{
    border: solid 1px red;
}

.product-edit-options-colors-div div{
    height: 30px;
    width: 30px;
}

.product-edit-options-colors-title{
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-colors-container{
    height: 50px;
    width: 100%;
}

.product-edit-options-shape-title{
    margin-right: 10px;
    margin-top: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-shape{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-shape div{
    -webkit-flex-grow: 0; /* Safari 6.1+ */
    -webkit-flex-shrink: 0; /* Safari 6.1+ */
    -webkit-flex-basis: 30px; /* Safari 6.1+ */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30px;
    margin-right: 10px;
    height: 30px;
    font-size: 20px;
}

.product-edit-options-shape-container{
    width: 100%;
    height: 50px;
}

.product-edit-options-shape button{
    color: black; 
    margin-top: -4px;
}

.product-edit-options-shape-title{
    width: 25%;
    text-align: left;
    margin-left: 10px;
}

.product-edit-options-shape-selected{
    border: solid 1px red;
    padding-bottom: 10px;
}

.product-edit-options-quantity-container{
    width: 100%;
    height: 50px;
}

.product-edit-options-quantity-title{
    float: left;
    width: 25%;
    text-align: left;
    margin-top: 14px;
    margin-left: 10px;
}

.product-edit-options-quantity{
    float: left;
    width: 100%;
}

.product-edit-options-quantity-input{
    width: 100px;
}

.product-edit-buttons{
    margin-top: 20px;
    text-align: center;
}

.product-edit-buttons button:first-child{
    margin-right: 10px;
    margin-left: 10px;
}

.product-edit-buttons button{
    width: 130px;
}

.product-edit-tabs-container{
    margin-top: 40px;
}

.product-edit-color-selected{
    width: 10px;
    background-color: #fff;
    height: 10px;
    display: block;
    margin-top: 35%;
    margin-left: 35%;
}

.product-edit-options-style-title{
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-style-container{
    height: 50px;
    width: 100%;
}

.product-edit-options-style{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-style-div{
    -webkit-flex-grow: 0; /* Safari 6.1+ */
    -webkit-flex-shrink: 0; /* Safari 6.1+ */
    -webkit-flex-basis: 32px; /* Safari 6.1+ */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32px;
    margin-right: 10px;
    height: 32px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 11px;
    text-transform: capitalize;
}

.product-edit-options-style-selected{
    border: solid 1px red;
}

.product-edit-options-style-div:hover{
    border: solid 1px #ccc;    
}

.product-edit-options-size-title{
    margin-right: 10px;
    margin-top: 10px;
    float: left;
    width: 25%;
    text-align: left;
    margin-left: 10px;
}

.product-edit-options-material-title{
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-material-container{
    height: 50px;
    width: 100%;
}

.product-edit-options-material{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-note-container{
    height: 140px;
    width: 100%;    
}

.product-edit-options-note{    
    width: 100%;
    background-color: #fff;
}

.product-edit-options-colors-label{
    width: 65px !important;
    margin-top: 4px;
}

@media(max-width: 1150px){
    .grid-picture-description{
        width: 100% !important;
    }
}

@media(max-width: 400px){
    .product-edit-options-colors-title, 
    .product-edit-options-shape-title,
    .product-edit-options-style-title,
    .product-edit-options-material-title{
        float: none;
        font-weight: bold;
    }
    .product-edit-options-colors{
        margin-left: 10px !important;
    }
    .product-edit-options-shape-container,
    .product-edit-options-material-container{
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
    .product-edit-options-size-title{
        margin-left: 10px;
        margin-top: 18px;
        font-weight: bold;
    }
    .product-edit-options-quantity-input{
        width: 50px; 
    }
}
