
.upload-file-contaner{
    border: dashed 2px #000;
    height: 60px;
    min-height: 60px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    text-align: center;
    background-color: #f6f7f8;
    padding-top: 10px;
}

.upload-file-span{       
    font-size: 60pt;
    color: #5096ff;
    margin-top: 30px;
    margin-bottom: 30px;    
}

.upload-file-ul{
    list-style-type:none;
    margin-left: 15%;
    margin-top: 15px;
}

