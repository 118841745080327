#divCard figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;	
	display: block;
    max-width: 318px;
    max-height: 209px;
	width: auto;
	height: auto;
    margin-left: auto;
    margin-right: auto;    
}
#divCard figure:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);    
}
figure {
	width: 100%;
	height: 200px;
	margin: 0;
	padding: 0;
	background: #fff;
	overflow: hidden;
}
