#divUserSignUpContainer{
    text-align: center;
    margin-top: 8%;
    /* width: 87%;
    margin-right: auto;
    margin-left: auto; */
}

#txtUserSignUpLogin, #txtUserSignUpPassword, 
#txtUserSignUpName, #txtUserSignUpEmail{
    height: 15px !important;
    width: 500px !important;
    font-size: 15px;
    border-bottom: 0px !important;    
}

.signup-container{    
    margin: auto;
    background-color: #f8f8f8;
    padding: 80px;
    border: solid 1px #ccc;    
    border-radius: 3px;
}

.validate-error{
    color: red !important;
}

.MuiButton-label-24{
    font-size: 14px !important;
    min-width: 140px;
}

.div-user-save-button{
    margin-top: "20px";
    margin-left: "37%";
}

@media(max-width:600px){
    #txtUserSignUpLogin, #txtUserSignUpPassword, 
    #txtUserSignUpName, #txtUserSignUpEmail{
        width: 100% !important;
    }
   
    .div-user-save-button{
        padding-left: 0 !important;
    }
}

