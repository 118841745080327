.contactus-container{
    margin-top: 100px;
    padding: 30px;
}

.contactus-body-container{
    margin: auto;
    background-color: #f8f8f8;
    padding: 80px;
    border: solid 1px #ccc;
    width: 100%;
    /*box-shadow:0px 0px 1px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow:0px 0px 1px 1px rgba(0,0,0,0.2);
    -moz-box-shadow:0px 0px 1px 1px rgba(0,0,0,0.2);*/
    border-radius: 3px;
}

#txtContactUsAbout{
    height: 300px !important;
}

.loading-contactus-wait{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    background-color: #fff;
    margin-top: -100px;
    opacity: 0.8;
}

.loading-contactus-wait-none{
    display: none;
}

.loading-contactus-wait-div-image{
    width: 100%;
    height: 100%;
}

.loading-contactus-wait-image{
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 15%;
    margin-bottom: 10px;
}

.loading-contactus-wait-div-image span{
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 10px;
}

@media(max-width: 750px){
   .contactus-body-container{
       padding: 20px;
   } 
}