.div-sort-color{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

@media(max-width: 1150px){   
    /* .MuiTabs-scrollButtons-165{
        color: #000 !important;
    }
    .jss158{
        color: #000 !important;
    } */

    #sortTabs > div:nth-child(2) > button{
        color: #000 !important;
    }
}