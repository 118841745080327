html{
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.wait-dialog{
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  margin-top: -100px;
  opacity: 0.8;
}

.wait-dialog-none{
  display: none;
}

.wait-dialog-div-image{
  width: 100%;
  height: 100%;
}

.wait-dialog-div-image img{
  margin-right: 50%;
  margin-left: 50%;
  margin-top: 15%;
  margin-bottom: 10px;
}

.wait-dialog-div-image span{
  margin-right: 50%;
  margin-left: 50%;
  margin-top: 10px;
}



#divCard figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;	
	display: block;
    max-width: 318px;
    max-height: 209px;
	width: auto;
	height: auto;
    margin-left: auto;
    margin-right: auto;    
}
#divCard figure:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);    
}
figure {
	width: 100%;
	height: 200px;
	margin: 0;
	padding: 0;
	background: #fff;
	overflow: hidden;
}

.div-sort-color{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

@media(max-width: 1150px){   
    /* .MuiTabs-scrollButtons-165{
        color: #000 !important;
    }
    .jss158{
        color: #000 !important;
    } */

    #sortTabs > div:nth-child(2) > button{
        color: #000 !important;
    }
}
.sort-shape-text{
    margin-top: -5px;
    margin-left: 5px;
}
.span-color-name{
    text-transform: capitalize;
}
.home-slider-image{
    width: auto;
    height: auto;    
  }
  
  .home-slider-image img{
    width: 100% !important; 
    height: 80vh;   
  }  
  
  .home-products-btn{
    position: fixed;
    z-index: 1000;
    top: 65%;
    left: 43%;
  }

  .home-products-btn button{
    width: 200px;
    height: 55px;
    border: solid 2px #fff;
  }

  @media(max-width: 800px){
    .home-products-btn{
        left: 25%;
        top: 40%;
    }
  }

.div-main-container{
    background-color: #e9e6e6 !important;
    height: 100%;
}

.grid-picture-description{
    background-color: #fff !important; 
    min-height: 95vh !important;
    width: 66% !important;
    margin-top: 20px !important;
}

.product-edit-main-image{
    margin-top: 10px;
    width: 90%;
}

.product-edit-title{
    padding-top: 10px;
}

.product-edit-right-border{
    border-right: solid 1px #ccc;
}

.product-edit-bottom-border{
    border-bottom: solid 1px #ccc;
}

.product-edit-separator{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 10px;
}

.product-edit-description{
    text-align: left;
    padding: 10px;
}

.product-edit-options{
    background-color: #f8f8f8;
    margin-top: 20px;
    padding: 10px;
}

.product-edit-options-colors{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-colors-div{
    -webkit-flex-grow: 0; /* Safari 6.1+ */
    -webkit-flex-shrink: 0; /* Safari 6.1+ */
    -webkit-flex-basis: 32px; /* Safari 6.1+ */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32px;
    margin-right: 10px;
    height: 32px;
    padding-left: 2px;
    padding-top: 2px;
}

.product-edit-options-colors-div:hover{
    border: solid 1px #ccc;
}

.product-edit-options-colors-selected{
    border: solid 1px red;
}

.product-edit-options-colors-div div{
    height: 30px;
    width: 30px;
}

.product-edit-options-colors-title{
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-colors-container{
    height: 50px;
    width: 100%;
}

.product-edit-options-shape-title{
    margin-right: 10px;
    margin-top: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-shape{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-shape div{
    -webkit-flex-grow: 0; /* Safari 6.1+ */
    -webkit-flex-shrink: 0; /* Safari 6.1+ */
    -webkit-flex-basis: 30px; /* Safari 6.1+ */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30px;
    margin-right: 10px;
    height: 30px;
    font-size: 20px;
}

.product-edit-options-shape-container{
    width: 100%;
    height: 50px;
}

.product-edit-options-shape button{
    color: black; 
    margin-top: -4px;
}

.product-edit-options-shape-title{
    width: 25%;
    text-align: left;
    margin-left: 10px;
}

.product-edit-options-shape-selected{
    border: solid 1px red;
    padding-bottom: 10px;
}

.product-edit-options-quantity-container{
    width: 100%;
    height: 50px;
}

.product-edit-options-quantity-title{
    float: left;
    width: 25%;
    text-align: left;
    margin-top: 14px;
    margin-left: 10px;
}

.product-edit-options-quantity{
    float: left;
    width: 100%;
}

.product-edit-options-quantity-input{
    width: 100px;
}

.product-edit-buttons{
    margin-top: 20px;
    text-align: center;
}

.product-edit-buttons button:first-child{
    margin-right: 10px;
    margin-left: 10px;
}

.product-edit-buttons button{
    width: 130px;
}

.product-edit-tabs-container{
    margin-top: 40px;
}

.product-edit-color-selected{
    width: 10px;
    background-color: #fff;
    height: 10px;
    display: block;
    margin-top: 35%;
    margin-left: 35%;
}

.product-edit-options-style-title{
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-style-container{
    height: 50px;
    width: 100%;
}

.product-edit-options-style{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-style-div{
    -webkit-flex-grow: 0; /* Safari 6.1+ */
    -webkit-flex-shrink: 0; /* Safari 6.1+ */
    -webkit-flex-basis: 32px; /* Safari 6.1+ */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32px;
    margin-right: 10px;
    height: 32px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 11px;
    text-transform: capitalize;
}

.product-edit-options-style-selected{
    border: solid 1px red;
}

.product-edit-options-style-div:hover{
    border: solid 1px #ccc;    
}

.product-edit-options-size-title{
    margin-right: 10px;
    margin-top: 10px;
    float: left;
    width: 25%;
    text-align: left;
    margin-left: 10px;
}

.product-edit-options-material-title{
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    width: 25%;
    text-align: left;
}

.product-edit-options-material-container{
    height: 50px;
    width: 100%;
}

.product-edit-options-material{
    display: flex;
    display: -webkit-flex;
    height: 50px;
}

.product-edit-options-note-container{
    height: 140px;
    width: 100%;    
}

.product-edit-options-note{    
    width: 100%;
    background-color: #fff;
}

.product-edit-options-colors-label{
    width: 65px !important;
    margin-top: 4px;
}

@media(max-width: 1150px){
    .grid-picture-description{
        width: 100% !important;
    }
}

@media(max-width: 400px){
    .product-edit-options-colors-title, 
    .product-edit-options-shape-title,
    .product-edit-options-style-title,
    .product-edit-options-material-title{
        float: none;
        font-weight: bold;
    }
    .product-edit-options-colors{
        margin-left: 10px !important;
    }
    .product-edit-options-shape-container,
    .product-edit-options-material-container{
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
    .product-edit-options-size-title{
        margin-left: 10px;
        margin-top: 18px;
        font-weight: bold;
    }
    .product-edit-options-quantity-input{
        width: 50px; 
    }
}


.carousel-container{
  max-width: 400px;
  max-height: 500px;    
  margin: auto;
  padding: 10px;
}

.project-edit-image{
  width: auto;
  height: auto;
  max-width: 400px;
  max-height: 400px; 
}

.project-edit-image img{
  width: auto !important;
  height: auto;
  max-width: 400px;
  max-height: 400px; 
}

.project-edit-thumbnail{  
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 100px; 
}

.project-edit-thumbnail img{  
  width: auto !important;
  height: auto;
  max-width: 100px;
  max-height: 100px; 
}


#divUserSignUpContainer{
    text-align: center;
    margin-top: 8%;
    /* width: 87%;
    margin-right: auto;
    margin-left: auto; */
}

#txtUserSignUpLogin, #txtUserSignUpPassword, 
#txtUserSignUpName, #txtUserSignUpEmail{
    height: 15px !important;
    width: 500px !important;
    font-size: 15px;
    border-bottom: 0px !important;    
}

.signup-container{    
    margin: auto;
    background-color: #f8f8f8;
    padding: 80px;
    border: solid 1px #ccc;    
    border-radius: 3px;
}

.validate-error{
    color: red !important;
}

.MuiButton-label-24{
    font-size: 14px !important;
    min-width: 140px;
}

.div-user-save-button{
    margin-top: "20px";
    margin-left: "37%";
}

@media(max-width:600px){
    #txtUserSignUpLogin, #txtUserSignUpPassword, 
    #txtUserSignUpName, #txtUserSignUpEmail{
        width: 100% !important;
    }
   
    .div-user-save-button{
        padding-left: 0 !important;
    }
}


#divLoginUserContainer{
    text-align: center;
    margin-top: 8%;
}

#txtLoginUserLogin, #txtLoginUserPassword, 
#txtLoginUserName, #txtLoginUserEmail{
    height: 15px !important;
    width: 500px !important;
    font-size: 15px;
    border-bottom: 0px !important;    
}

.login-container{
    margin: auto;
    background-color: #f8f8f8;
    padding: 80px;
    border: solid 1px #ccc;
    border-radius: 3px;
}

.validate-error{
    color: red !important;
}

.MuiButton-label-24{
    font-size: 14px !important;
    min-width: 140px;
}

@media(max-width:600px){
    #txtLoginUserLogin, #txtLoginUserPassword, 
    #txtLoginUserName, #txtLoginUserEmail{
        width: 100% !important;
    }    
}

.upload-file-contaner{
    border: dashed 2px #000;
    height: 60px;
    min-height: 60px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    text-align: center;
    background-color: #f6f7f8;
    padding-top: 10px;
}

.upload-file-span{       
    font-size: 60pt;
    color: #5096ff;
    margin-top: 30px;
    margin-bottom: 30px;    
}

.upload-file-ul{
    list-style-type:none;
    margin-left: 15%;
    margin-top: 15px;
}



.upload-file-contaner{
    border: dashed 2px #000;
    height: 60px;
    min-height: 60px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    text-align: center;
    background-color: #f6f7f8;
    padding-top: 10px;
}

.upload-file-span{       
    font-size: 60pt;
    color: #5096ff;
    margin-top: 30px;
    margin-bottom: 30px;    
}

.upload-file-ul{
    list-style-type:none;
    margin-left: 15%;
    margin-top: 15px;
}


#divResetPasswordContainer{
    text-align: center;
    margin-top: 8%;
}

#txtResetPasswordNew, #txtResetPasswordConfirmPassword{
    height: 15px !important;
    width: 500px !important;
    font-size: 15px;
    border-bottom: 0px !important;    
}

.reset-password-container{    
    margin: auto;
    background-color: #f8f8f8;
    padding: 80px;
    border: solid 1px #ccc;   
    border-radius: 3px;
}

.validate-error{
    color: red !important;
}

.MuiButton-label-24{
    font-size: 14px !important;
    min-width: 140px;
}
.contactus-container{
    margin-top: 100px;
    padding: 30px;
}

.contactus-body-container{
    margin: auto;
    background-color: #f8f8f8;
    padding: 80px;
    border: solid 1px #ccc;
    width: 100%;
    /*box-shadow:0px 0px 1px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow:0px 0px 1px 1px rgba(0,0,0,0.2);
    -moz-box-shadow:0px 0px 1px 1px rgba(0,0,0,0.2);*/
    border-radius: 3px;
}

#txtContactUsAbout{
    height: 300px !important;
}

.loading-contactus-wait{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    background-color: #fff;
    margin-top: -100px;
    opacity: 0.8;
}

.loading-contactus-wait-none{
    display: none;
}

.loading-contactus-wait-div-image{
    width: 100%;
    height: 100%;
}

.loading-contactus-wait-image{
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 15%;
    margin-bottom: 10px;
}

.loading-contactus-wait-div-image span{
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 10px;
}

@media(max-width: 750px){
   .contactus-body-container{
       padding: 20px;
   } 
}
