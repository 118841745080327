#divLoginUserContainer{
    text-align: center;
    margin-top: 8%;
}

#txtLoginUserLogin, #txtLoginUserPassword, 
#txtLoginUserName, #txtLoginUserEmail{
    height: 15px !important;
    width: 500px !important;
    font-size: 15px;
    border-bottom: 0px !important;    
}

.login-container{
    margin: auto;
    background-color: #f8f8f8;
    padding: 80px;
    border: solid 1px #ccc;
    border-radius: 3px;
}

.validate-error{
    color: red !important;
}

.MuiButton-label-24{
    font-size: 14px !important;
    min-width: 140px;
}

@media(max-width:600px){
    #txtLoginUserLogin, #txtLoginUserPassword, 
    #txtLoginUserName, #txtLoginUserEmail{
        width: 100% !important;
    }    
}